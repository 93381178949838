import { env } from 'env.mjs';

const isProduction = env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export const AISCOUT_CLUB_ID = 92;

export const CHELSEA_CLUB_ID = 24;

export const BURNLEY_CLUB_ID = 17;

export const MAHD_SPORTS_ACADEMY_ID = 99;

export const MANCHESTER_UNITED_CLUB_ID = 147;

export const SENEGAL_CLUB_ID = isProduction ? 174 : 169;

export const MLS_CLUB_ID = isProduction ? 163 : 171;

export const ENGLAND_COUNTRY_ID = 1;

export const PRO_CLUB_TYPE_ID_TRIALING = 1;
export const PRO_CLUB_TYPE_ID_SIGNED = 2;

export const SENEGAL_DEFAULT_PROFILE_IMAGE_URL =
  'user/defaults/epl/club-badges/senegal';

export const SENEGAL_EXCLUDE_ENTRY_RATING_TYPE_IDS = isProduction
  ? [412, 414, 563, 564]
  : [539, 540, 103, 307];

export const sendGridEmailTemplates = {
  inviteScout: 'd-1a0109db06974ac9b463accc4216c2df',
  inviteTrialParents: 'd-0d5ad437088c41afbfe506cea7e84f79',
  inviteTrialPlayer: 'd-09ed0e2ae6e3486e91148f720fc0332a',
};

export const errorCodes = {
  accessErrorCode: 403,
  notFoundErrorCode: 404,
  unknownErrorCode: 500,
};

export const errorMessages = {
  filterExists: 'Filter already available in the filters list',
  noFullAccessFilter: 'You cannot add multiple full access filters',
};

export const UK_SCHOOL_YEAR_SEASON_END_MONTH = 5; // May
export const UK_SCHOOL_YEAR_SEASON_START_MONTH = 8; // August
export const UK_SCHOOL_YEAR_SEASON_START_DAY = 31;

export const UK_SCHOOL_YEAR_TERM_START_MONTH = 9; // September
export const UK_SCHOOL_YEAR_TERM_START_DAY = 1;
